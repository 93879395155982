import React from "react";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader";
import { axiosget } from "../../helpers/httprequest";
import Receipt from "../Receipt";
import ReceiptHeader from "../../components/ReceiptHeader";

const AcceptanceLetter = () => {
  const [data, setdata] = React.useState();
  const [loading, setloading] = React.useState(true);
  const [error, seterror] = React.useState(0);
  const { id } = useParams();

  React.useEffect(() => {
    (async () => {
      try {
        const req = await axiosget(`ms_acceptance_fee_rrr/letter/${id}`);
        if (req.data?.type) {
          throw new Error(req.data?.message);
        }
        const { data } = req;
        setdata(data);
        setloading(false);
      } catch ({ message }) {
        seterror(message);
        setloading(false);
      }
    })();
    //eslint-disable-next-line
  }, []);
  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            flexWrap: "wrap",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          {error ? (
            <div>{error}</div>
          ) : (
            <div className="container">
              <ReceiptHeader
                office={<>CLEARANCE ACCEPTANCE</>}
                subs={
                  <i style={{ fontSize: 15 }}>(DIRECTORATE OF UNDEGRADUATE)</i>
                }
              />
              <br />
              <div className="letterto">
                <div className="">
                  <b className="text-uppercase">ATBCOE/ADM/AU/UGP/01</b>
                </div>
                <div className="">Date: {new Date().toDateString()}</div>
              </div>
              <br />
              <div className="body">
                <div>The Director</div>
                <div>Undergraduate Program</div>
                <div className="lettertitle">Admission Acceptance Letter</div>
                <div style={{ textAlign: "justify" }}>
                  I,{" "}
                  <b>
                    <u>
                      {data &&
                        String(data?.details?.payerName).toLocaleUpperCase()}
                    </u>
                  </b>{" "}
                  hereby accept the admission offered to me and pledge to abide
                  by the rules and regulations of the college.
                </div>

                <div className="text-center">
                  Signature & Date (Below)
                  <br />
                  <br />
                  <br />
                  ......................................................................
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AcceptanceLetter;

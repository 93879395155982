import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { contextapi } from "./helpers/contextapi";
import Modal from "react-modal";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import ProtectedRoute from "./helpers/ProtectedRoute";
import Referee from "./pages/Referee";
import BioData from "./pages/BioData";
import NewApplication from "./pages/NewApplication";
import PayFee from "./pages/PayFee";
import Uploads from "./pages/Uploads";
import Final from "./pages/Final";
import Print from "./pages/Print";
import Letter from "./pages/Letter";
import Activate from "./pages/Activate";
import Forogt from "./pages/Forgot";
import OLevel from "./pages/OLevel";
import ALevel from "./pages/ALevel";
import Verify from "./pages/Verify";
import Admission from "./pages/Admission";
import Receipt from "./pages/Receipt";
import Acceptance from "./pages/Acceptance";
import AcceptanceLetter from "./pages/AcceptanceLetter";

const App = () => {
  const [values, setvalues] = React.useState([]);
  Modal.setAppElement("#root");
  return (
    // <React.StrictMode>
    <contextapi.Provider value={{ values, setvalues }}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/forgot" element={<Forogt />} />
          <Route path="/account" element={<Register />} />
          <Route path="/letter/:id" element={<Letter />} />
          <Route path="/activate/:id" element={<Activate />} />
          <Route path="/verify-payment/:id" element={<Letter />} />
          <Route path="/register" element={<Register />} />
          <Route path="/verify" element={<Verify />} />

          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admission"
            element={
              <ProtectedRoute>
                <Admission />
              </ProtectedRoute>
            }
          />
          <Route
            path="/receipt"
            element={
              <ProtectedRoute>
                <Receipt />
              </ProtectedRoute>
            }
          />
          <Route
            path="/accept"
            element={
              <ProtectedRoute>
                <Acceptance />
              </ProtectedRoute>
            }
          />
          <Route
            path="/acceptance_letter/:id"
            element={
              <ProtectedRoute>
                <AcceptanceLetter />
              </ProtectedRoute>
            }
          />
          <Route
            path="/final"
            element={
              <ProtectedRoute>
                <Final />
              </ProtectedRoute>
            }
          />
          <Route
            path="/olevel"
            element={
              <ProtectedRoute>
                <OLevel />
              </ProtectedRoute>
            }
          />
          <Route
            path="/print"
            element={
              <ProtectedRoute>
                <Print />
              </ProtectedRoute>
            }
          />
          <Route
            path="/parents"
            element={
              <ProtectedRoute>
                <Referee />
              </ProtectedRoute>
            }
          />
          <Route
            path="/alevel"
            element={
              <ProtectedRoute>
                <ALevel />
              </ProtectedRoute>
            }
          />
          <Route
            path="/pay"
            element={
              <ProtectedRoute>
                <PayFee />
              </ProtectedRoute>
            }
          />
          <Route
            path="/uploads"
            element={
              <ProtectedRoute>
                <Uploads />
              </ProtectedRoute>
            }
          />
          <Route
            path="/bio"
            element={
              <ProtectedRoute>
                <BioData />
              </ProtectedRoute>
            }
          />
          <Route
            path="/start"
            element={
              <ProtectedRoute>
                <NewApplication />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </contextapi.Provider>
    // </React.StrictMode>
  );
};

export default App;
